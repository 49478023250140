import React from "react";
import FeatureCard from "../../components/FreatureCard";
import img1 from "../../assets/image/media/about/svg/AboutDignity.svg";
import img2 from "../../assets/image/media/about/svg/AboutIntegrity.svg";
import img3 from "../../assets/image/media/about/svg/AboutTeamwork.svg";
import img4 from "../../assets/image/media/about/svg/AboutQuality.svg";
import img5 from "../../assets/image/media/about/svg/AboutCourage.svg";
import img6 from "../../assets/image/media/about/svg/AboutInnovation.svg";

const items = [
  {
    img: img1,
    title: "Dignity matters",
    content:
      "We care about our people, we trust in their abilities, and we support their growth—both personally and professionally.",
  },
  {
    img: img2,
    title: "Integrity matters",
    content:
      "We operate with transparency, we communicate sincerely, and we’re honest about our successes as well as our mistakes.",
  },
  {
    img: img3,
    title: "Teamwork matters",
    content:
      "The collective energy, intelligence, and contributions of our team members are what drive the success of every project.",
  },
  {
    img: img4,
    title: "Quality matters",
    content:
      "We pride ourselves on exemplary products and impeccable service, and we never hand over anything we’re not proud of.",
  },
  {
    img: img5,
    title: "Courage matters",
    content:
      "When things get tough, we get more focused, more disciplined, and more creative—whatever the challenge ahead.",
  },
  {
    img: img6,
    title: "Innovation matters",
    content:
      "Our motivation as a team stems from testing new possibilities, encouraging new ideas, and striving to set new standards.",
  },
];

const Features2 = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-6 col-xs-6">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83 mb-6">
                What matters to us?
              </h2>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-4 col-lg-4 col-md-4 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard options={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features2;

import React from "react";
import FeatureCard2 from "../../components/FreatureCard2";
import img1 from "../../assets/image/media/about/png/AboutExperts.png";
import img2 from "../../assets/image/media/about/png/AboutCreative.png";
import img3 from "../../assets/image/media/about/png/AboutValue.png";
import img4 from "../../assets/image/media/about/png/AboutCollab.png";
import img5 from "../../assets/image/media/about/png/AboutPerfect.png";
import img6 from "../../assets/image/media/about/png/AboutTrends.png";
import img7 from "../../assets/image/media/about/png/AboutTrust.png";
import img8 from "../../assets/image/media/about/png/AboutTenacious.png";

const items = [
  {
    icon: img1,
    title: "We’re experts",
    content:
      "Our team is composed of top talent — all specialists in their disciplines.",
  },
  {
    icon: img2,
    title: "We’re creative",
    content:
      "Integrity, transparency, and honesty aren’t just buzzwords — we live by them.",
  },
  {
    icon: img3,
    title: "We’re value led",
    content:
      "we believe in our mission, and we're commited to improving Cloudstack to meet the needs of our users now and in the future.",
  },
  {
    icon: img4,
    title: "We’re collaborative",
    content:
      "We thrive on teamwork. Your ideas plus our expertise equal magic.",
  },
  {
    icon: img5,
    title: "We’re perfectionists",
    content:
      "“Good enough” is not in our vocabulary—only the best solution matters.",
  },
  {
    icon: img6,
    title: "We’re trendsetters",
    content:
      "We don’t template, borrow or copy—we create original work every time.",
  },
  {
    icon: img7,
    title: "We’re trustworthy",
    content:
      "There’s never a compromise on quality—industry standards are a given.",
  },
  {
    icon: img8,
    title: "We’re tenacious",
    content:
      "Roadblocks and barriers are just opportunities for a breakthrough.",
  },
];

const Features = () => {
  return (
    <div className="pt-24 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* section title */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-6 col-xs-6">
            <div
              className="text-center mb-7 mb-lg-17"
              data-aos="fade-up"
              data-aos-duration={600}
            >
              <h2 className="font-size-10 letter-spacing-n83 mb-6">
                Why choose Cloudstack?
              </h2>
              <p>
                We’re a vibrant team of dynamic professionals from the fields of
                information technology and business intelligence.
              </p>
            </div>
          </div>
        </div>
        {/* End Section title */}
        <div className="row justify-content-center">
          {items.map((item, index) => (
            <div
              className="col-xl-6 col-lg-5 col-md-6 col-sm-9 col-xs-11 mb-7 mb-lg-15"
              data-aos="fade-up"
              data-aos-duration={900}
              key={index}
            >
              <FeatureCard2 options={item} />
            </div>
          ))}
        </div>
        <p>
          The list goes on, of course. With Cloudstack, there is no looking
          back, only dreaming of the next possibility!
        </p>
      </div>
    </div>
  );
};

export default Features;

import React from "react";
import imgBg from "../../assets/image/media/about/png/about_hero.png";
const AboutSec = () => {
  return (
    <div
      className="pt-24 pt-md-26 pt-lg-24 pb-12 pb-md-16 pb-lg-22 bg-images"
      css={`
        background-image: url(${imgBg});
      `}
    >
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-9 col-lg-11 col-md-14 pt-1">
            <div
              className="mb-11 mb-lg-24"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-5 about-heading">
                Creativity is our journey — quality is our destination.
              </h2>
              <p className="font-size-7 mb-5 about-content">
                Passion for driving our clients’ success and conviction in our
                ability to untangle complex problems is what brings Cloudstack
                to life.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default AboutSec;

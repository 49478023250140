import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import Mission from "../sections/about/Mission";
import Features from "../sections/about/Features";
import Brands from "../sections/homepage/brands";
import CtaSec from "../sections/homepage/cta";
import AboutSec from "../sections/about/AboutSec";
import Features2 from "../sections/about/Features2";


const AboutPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              
              <Link to="/contact">
              <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                Let's talk
              </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <AboutSec />
        <Mission />
        <Features2 />
        <Features />
        <Brands />
        <CtaSec />
        
      </PageWrapper>
    </>
  );
};
export default AboutPage;

import React from "react";

const Mission = () => {
  return (
    <div className="pt-14 pt-md-26 pt-lg-30 pb-14 pb-md-18 pb-lg-24">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11 ">
            <div
              className="text-center mb-11 mb-lg-24"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-11">Our Vision</h2>

              <p className="font-size-6  mb-7">
                To become the most proficient, sustainable, and innovative SaaS
                development company of the century.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11 ">
            <div
              className="text-center mb-11 mb-lg-24"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-11">Our mission</h2>

              <p className="font-size-6  mb-7">
                To design, develop and support digital products that empower
                businesses to scale and succeed by connecting with their
                customers in novel ways.
              </p>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Mission;

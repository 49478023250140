import React from "react";

const FeatureCard = ({ options }) => {
  return (
                <div>
                <div className=" dodger-blue-3 rounded-10 mb-10  mx-auto mx-md-0">
                    <img className="w-100" src={options.img} alt="image" />
                  </div>
                  <div className="text-center">
                    <h3 className="font-size-8 mb-6">{options.title}</h3>
                    <p className="font-size-5 line-height-28 mb-0">
                    {options.content}
                    </p>
                  </div>
                </div>
  );
};

export default FeatureCard;
